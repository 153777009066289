import { Component, Input, OnInit } from '@angular/core';
import { IVisitProductConfig, IVisitProductData, VisitProductDataItemType } from './visit-product-overview.declarations';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'visit-product-overview',
  templateUrl: './visit-product-overview.component.html',
  styleUrls: ['./visit-product-overview.component.scss']
})
export class VisitProductOverviewComponent implements OnInit {

  VisitProductDataItemType = VisitProductDataItemType;

  @Input()
  config: IVisitProductConfig;

  ref: string;
  data: IVisitProductData;

  constructor() {
  }

  ngOnInit(): void {
    if (!this.config) {
      console.warn('Missing config');

      return;
    }

    this.ref = this.config.ref;
    this.data = this.config.data;
  }

  getImageSrc(img: string): string {
    return `/assets/visit/data/${this.ref}/${img}`;
  }
}
