<div style="display:block; top: 0; position: absolute; background-color:transparent;color:#FFFFFF;z-index:10000000;width:100%;height:68px;text-align:center;">
        <img src="/assets/visit/img/logo.png" style="margin-top: 23px;" alt="logo" width="143" height="22">
</div>
<iframe id="chanel" class="w-100 krpanoIframe" [src]="krpanoUrl" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
<div class="videoIntro" *ngIf="!destroyVideo" [class.hide]="!showVideo" (transitionend)="transitionEnd($event)">
        <video class="videoIntroContent" muted autoplay playsinline oncanplay="this.play()" onloadedmetadata="this.muted = true" (ended)="closeVideo()">
                <source [src]="videoIntroUrl" type="video/mp4">
        </video>
        <div class="skipIntro" (click)="closeVideo()" [innerHtml]="skipIntroText"></div>
</div>
