<div class="modal-header">
    <div class="modal-header-button-close" (click)="submitClose()">
        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Atomes/Buttons/Close</title>
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
                <g id="Molecules/modalHeader" transform="translate(-1150.000000, -14.000000)" stroke="#000000">
                    <g id="Line" transform="translate(1150.000000, 14.000000)">
                        <line x1="0.5" y1="0.5" x2="13.5" y2="13.5"></line>
                        <line x1="0.5" y1="0.5" x2="13.5" y2="13.5" transform="translate(7.000000, 7.000000) scale(-1, 1) translate(-7.000000, -7.000000) "></line>
                    </g>
                </g>
            </g>
        </svg>
    </div>
</div>
<div mat-dialog-content>
    <visit-product-overview [config]="data"></visit-product-overview>
    <div class="modal-content-extra"></div>
</div>
