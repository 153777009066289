import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  static languages: [
    { code: 'en_US', title: 'English' },
    { code: 'fr_FR', title: 'Français' },
    { code: 'ja_JP', title: '日本語' },
    { code: 'zh_CN', title: '简体中文' },
    { code: 'zh_TW', title: '繁體中文' },
    { code: 'ko_KR', title: '한국어' },
    { code: 'en_ME', title: 'English Middle East' },
    { code: 'de_DE', title: 'Deutsch' },
    { code: 'es_ES', title: 'Español' },
    { code: 'it_IT', title: 'Italiano' },
    { code: 'ru_RU', title: 'Русский' }
  ];

  static DefLang = 'ru_RU';
  static CurrentLang = 'ru_RU';

  transform(value: any): string {

    if (typeof value === 'object') {
      if (value[TranslatePipe.CurrentLang] !== void 0) {
        return value[TranslatePipe.CurrentLang];
      }

      return value[TranslatePipe.DefLang];
    }

    return value;
  }

}
